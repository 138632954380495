import {Actions, Getters, Module, Mutations} from 'vuex-smart-module'
import jQuery from 'jquery';
import DrawerTreeApi from '@/api/tree';


// Recurse Functions
const closeChild = (items: IItems[]) => {
    items.map(item => {
        item.open = false;
        closeChild(item.children || [])
    })
}

const openChild = (state: IItems[], id?: string | number) => {
    state.map(item => {
        if (item.id === id && item.open) {
            item.open = false;
            closeChild(item.children || [])
        } else if (item.id === id && !item.open) {
            item.open = true
        } else {
            openChild(item.children || [], id)
        }
    })

    return state
}


class AutoTreeState {
    tree: IItems[] = [
        {
            id: 1,
            title: 'Все папки',
            to: '/files/1',
            open: false,
            children: []
        }
    ]

    id: any = 0
    data: any = null
}

class AutoTreeMutations extends Mutations<AutoTreeState> {
    OPEN_CHILD_CRUMBS_CHANGE(item: any) {
        item.open = true
    }

    OPEN_CHILD_CHANGE(payload: {state: IItems[]; id?: string | number}) {
       this.state.tree = openChild(payload.state, payload.id)
    }

    LOAD_TREE_CHANGE(payload: {item: any; data?: any}) {
        if (payload.item.children?.length) {
          payload.item.children = payload.data.result.content.folders.map(({id, title, children}: any) => {
            return {
              open: false,
              to: `/files/${id}`,
              children: children,
              title,
              id
          }
        })
        } else {
            payload.item.children = payload.data.result.content.folders.map(({id, title}: any) => ({
                open: false,
                to: `/files/${id}`,
                children: [],
                title,
                id
            }))
        }
    }
}

class AutoTreeActions extends Actions<AutoTreeState, Getters<AutoTreeState>, AutoTreeMutations, AutoTreeActions> {
    async LOAD_TREE_CRUMBS(crumbs: Array<{title: string; id: number | string}>) {
        let i = 0;
        while (i < crumbs.length - 1) {
            jQuery(`load_${crumbs[i].id}`).show(1);
            jQuery(`open_${crumbs[i].id}`).hide(1);
            await this.dispatch('LOAD_TREE', crumbs[i].id).then(async() => {
                jQuery(`load_${crumbs[i].id}`).hide(1);
                jQuery(`open_${crumbs[i].id}`).show(1);
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                await this.dispatch('OPEN_CHILD_CRUMBS', crumbs[i].id).then(() => i++)
            })
        }
    }

    async LOAD_TREE(id: string | number) {
        await DrawerTreeApi.loadTree(id).then(({data}) => {
            const editTree = (state: IItems[]) => {
                state.map((item) => {
                    if(item.id === id){
                        this.mutations.LOAD_TREE_CHANGE({item, data})
                    } else {
                        editTree(item.children || [])
                    }
                })
            }
            editTree(this.state.tree)
        })
    }

    OPEN_CHILD_CRUMBS(id: string | number) {
        const open = (state: IItems[], id?: string | number) => {
            state.map(item => {
                if (item.id === id && item.open) {}
                else if (item.id === id && !item.open) {
                    this.mutations.OPEN_CHILD_CRUMBS_CHANGE(item)
                } else {
                    open(item.children || [], id)
                }
            })

            return state
        }
        open(this.state.tree, id);
    }

    OPEN_CHILD(id: string | number) {
        this.mutations.OPEN_CHILD_CHANGE({state: this.state.tree, id: id})
    }
}

export const AutoTree = new Module({
    state: AutoTreeState,
    mutations: AutoTreeMutations,
    actions: AutoTreeActions
})