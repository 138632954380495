import {Mutations} from 'vuex-smart-module';
import {FolderState} from '@/vuex/Folder/store';
import { IContent, IFilesPayload, ISelect } from '@/vuex/Folder/IFolder';
import {VuexMixins} from '@/prototype';
import selectPlugin from '@/plugin/selected';

export const folder = (): IFolder => ({
    id: 1,
    title: '',
    'created_at': '',
    color: '',
    description: '',
    cover_url:'',
    permissions: 0,
    taggeds: false,
    marked: '',
    parent: 1,
    size: 0,
})

export const file = (): IFiles => ({
    'created_at': '',
    ext: '',
    'folder_id': 0,
    id: 0,
    marked: null,
    'original_src': '',
    'preview_src': '',
    size: '',
    taggeds: false,
    title: '',
    permissions: 0,
    src: '',
    'src_212': ''
})

export const defaultSelect = (): ISelect => ({
    files: [],
    folders: []
})

export class FolderMutations extends Mutations<FolderState> {


    // status

    CRUMB_SELECT(payload: {type: string; id: number}){
        const idx = this.state.select.folders.indexOf(payload.id);
        if(payload.type === 'open'){
            this.state.crumb = true;
            idx === -1 ? this.state.select.folders.push(payload.id) : null;
        } else {
            this.state.crumb = false
            idx !== -1 ? this.state.select.folders.splice(idx, 1) : null;
        }
    }

    EDIT_DATA(status: boolean) {
        this.state.loading = status
    }

    LOAD_FOLDERS(payload: boolean) {
        if (!payload) {
            this.state.select = defaultSelect()
            this.state.selectViews = []
        }
        this.state.loader = payload
    }

    SORT_TABLE_SUCCESS(data: IContent) {
        this.state.content = data
        this.state.loader = false
    }

    // EDIT CONTENT

    CREATE_FOLDER(folder: IFolder) {
        this.state.content.folders.push(folder)
    }

    DELETE(){
        Object.keys(this.state.select).forEach((key) => {
            this.state.select[key].map((id) => {
                // в mobx было бы проще )))
                this.state.content[key].splice(this.state.content[key].findIndex((item) => item.id === id), 1)
            })
        })
    }

    COLOR_EDIT(color: string) {
        this.state.select.folders.map((id) => {
            const index = this.state.content.folders.findIndex(item => item.id === id)
            this.state.content.folders[index].color = color
        })
    }

    COVER_EDIT(cover:string | null){
      this.state.select.folders.map((id) => {
        const index = this.state.content.folders.findIndex(item => item.id === id)
        this.state.content.folders[index].cover_url = cover
    })
    }

    RENAME_OBJECT(payload: { id: number | string | (string | number)[]; title: string | number; type: 'files' | 'folders' } | IFilesPayload[]) {
        if (payload instanceof Array) {
            payload.forEach((el, index) => {
                console.log(this.state.content);
                this.state.content.files = this.state.content.files.map((item) => {
                    return {
                        ...item,
                        title: item.title === payload[index].origin_name ? payload[index].new_name : item.title
                    }

                })
            })

        } else {
            this.state.content[payload.type as string] = this.state.content[payload.type as string].map((item) => ({
                ...item,
                title: item.id === payload.id ? payload.title.toString() : item.title
            }))
        }
    }

    EDIT_TAGGED(tagged: boolean) {
        Object.keys(this.state.select).forEach((key) => {
            this.state.select[key].map((id) => {
                // в mobx было бы проще )))
                this.state.content[key] = this.state.content[key].map((items: IFiles | IFolder) => ({
                    ...items,
                    taggeds: items.id === id ? tagged : items.taggeds
                }))
            })
        })
    }

    // SELECT/CLICK

    public FOLDER_SELECT(payload: ISelectElement[]) {
        Object.keys(this.state.select).forEach(key => this.state.select[key] = [])
        payload.map(({type, id,permissions }) => {
            permissions ? this.state.permissions = permissions : null
            type === 'folder' ? this.state.select.folders.push(id) : this.state.select.files.push(id)
        })
        this.state.selectViews = payload
    }

    public FOLDER_SELECTED_CLICK(id: number) {
        const idx = this.state.select.folders.indexOf(id);
        idx !== -1 ? this.state.select.folders.splice(idx, 1) : this.state.select.folders.push(id)
    }

    public FILE_SELECTED_CLICK(id: number) {
        const idx = this.state.select.files.indexOf(id);
        idx !== -1 ? this.state.select.files.splice(idx, 1) : this.state.select.files.push(id)
    }

    // for drag selected files and folders
    public COLLECT_SELECTED_ELEMENTS(payload: ISelectElement) {
        const idx = this.state.selectViews.findIndex((element) => element.id === payload.id);
        idx !== -1 ? this.state.selectViews.splice(idx, 1) : this.state.selectViews.push(payload)
    }

    public SELECT_FOLDER_IN_TRASH(id: number) {
        this.state.selectFolderInTrash = id
    }

    // FOLDERS

    public LOAD_FOLDER_SUCCESS(payload: IFolder) {
        this.state.folder = payload;
    }

    public LOAD_SELECT_FOLDER_SUCCESS(payload: IFolder) {
        this.state.selectFolder = payload
    }

    public LOAD_FOLDERS_SUCCESS(payload: IContent) {
        this.state.content = payload;
    }

    public LOAD_FOLDERS_SUCCESS_SEARCH(payload: IContent){
        this.state.content = {
            files: payload.files || [],
            folders: payload.folders || []
        }
    }

    public LOAD_RECENT_FOLDERS_SUCCESS(data: []) {
        this.state.content.folders = data
        this.state.loader = false
    }

    public LOAD_FAVORITE_FOLDERS_SUCCESS(data: []) {
        this.state.content.folders = data
        this.state.loader = false
    }

    public LOAD_MARKED_FOLDERS_SUCCESS(data: []) {
        this.state.content.folders = data
        this.state.content.files = []
        this.state.loader = false
    }

    public CHANGE_DESCRIPTION_SUCCESS(data: IFolder) {
        this.state.folder = data
        this.state.loader = false
    }

    public CHANGE_MOUSE_CLICKED(value: boolean) {
        this.state.isMouseClicked = value;
    }

    public CHANGE_HOTKEYS_SELECT(value: boolean) {
        this.state.hotKeysSelect = value;
    }

    public SET_UPLOADING_PERMISSIONS(value: number) {
        this.state.uploadingPermissions = value;
    }

    public CHANGE_IS_DRAGGING(value: boolean) {
        this.state.isDragging = value;
    }


    // FILES

    public OPEN_FILES(idx: number | null) {
        this.state.openFiles = idx;
    }

    public LOAD_RECENT_FILES_SUCCESS(data: []) {
        this.state.content.files = data
    }

    public LOAD_FAVORITE_FILES_SUCCESS(data: []) {
        this.state.content.files = data
    }

    public LOAD_FILE_SUCCESS(data: IFiles) {
        this.state.file = data;
    }

    public LOAD_SELECT_FILE_SUCCESS(data: IFiles) {
        this.state.selectFile = data
    }


    DESELECT(){

        if(this.state.openFiles) {
            this.state.openFiles > this.state.content.files.length ? this.state.openFiles = this.state.openFiles - 1 : null
        } else {
            const select: Element[] = []
            selectPlugin?.getSelection().map((el) => {
                el.classList.remove('selected')
                select.push(el)
            })
            select.map(el => selectPlugin?.removeFromSelection(el))
            selectPlugin?.clearSelection()

            this.state.select = defaultSelect()
            this.state.selectViews = []
            this.state.loading = false;
            document.body.click()
        }
    }

    //Trash

    LOAD_TRASH_SUCCESS(data: IContent) {
        this.state.content = data
        this.state.loader = false
    }

    // Public Link
    LOAD_PUBLIC_LINK_FOR_FOLDER_SUCCESS(link: string) {
        this.state.folderLink = link
    }

    // Size Selected Folders And Files
    LOAD_SIZE_SUCCESS(size: number) {
        this.state.size = size || 0
    }

    // Permissions Selected Folders And Files
    LOAD_PERMISSIONS_SELECTED_ELEMENTS_SUCCESS(permissions: number) {
        this.state.selectedPermissions = permissions
    }

    public SORT_LOCAL() {
        this.state.content.files.sort(VuexMixins.sort(localStorage.getItem('columnName') as string, localStorage.getItem('sort_order') as string))
        this.state.content.folders.sort(VuexMixins.sort(localStorage.getItem('columnName') as string, localStorage.getItem('sort_order') as string))
    }

    // For copy and paste by hotkeys
    COPY_CONTENT_BY_HOTKEY(data: ISelect) {
        this.state.hotKeysContent = data
    }

    // For arrow moves hotKeys
    CHANGE_SELECTED_FILE(id: number) {
        this.state.select.files = [id]
    }

    CHANGE_SELECTED_FOLDER(id: number) {
        this.state.select.folders = [id]
    }
}
